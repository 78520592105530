<template>
  <Navbar />
  <ScrollButton />

  <section class="banner-title" style="background-image: url(images/pcb-arge.png)">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Hizmetlerimiz</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Hizmetlerimiz</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <a id="arge"></a>
    <!-- Banner text end -->
  </section>

  <section class="page-container">
    <div class="auto-container">
      <div class="row">
        <div
          class="content-side pull-left col-lg-12 col-md-8 col-sm-12 col-xs-12"
        >
          <div class="blog-section">
            <div class="service-block">
              <div class="title-content">
                <h3>ARGE</h3>
              </div>
              <div class="text-content">
                <div class="right-img">
                  <img src="images/arge-design.png" class="img-size" />
                </div>
                <div  class="text-size-left">
                  <blockquote>
                    <p>
                      Sektörün ihtiyacını algılayıp talep edilen ürün veya
                      hizmetin dünyada benzeri olsun veya olmasın kendimize özgü
                      yenilik ve teknolojik özelliklerle donatıp araştırma ve
                      geliştirme ekibimizle bilişim vadisindeki arge
                      merkezimizde bütün arge tekniklerini kullanarak
                      üniversitelerle yaptığımız iş birlikleriyle disiplinli
                      çalışmalarla projelerimizle yön veriyoruz.
                    </p>
                  </blockquote>
                </div>
              </div>
              <a id="design"></a>
            </div>
            <div class="service-block mt-5">
              <div class="text-content">
                <div class="left-img">
                  <img src="images/tasarim3.png" class="img-size" />
                </div>
                <div class="text-size-right">
                  <div class="title-content">
                    <h3>Tasarım</h3>
                  </div>
                  <blockquote>
                    <p>
                      Deneyimli ekibimizle farklı sektörlerin beklentilerine
                      göre hazırlamış olduğumuz projelerin pcb ve elektronik
                      devre tasarımını ve yazılımını,otomasyon sistemlerinin
                      tasarımını ve yazılımını en yeni teknolojilerle ve en
                      verimli metotlarla yapmaktayız.
                    </p>
                  </blockquote>
                </div>
              </div>
              <a id="prototype"></a>
            </div>
            <div class="service-block">
              <div class="text-content">
                <div class="right-img">
                  <img src="images/tasarim.jpg" class="img-size" />
                </div>
                <div  class="text-size-left">
                  <div class="title-content">
                    <h3>Numune ve Prototip</h3>
                  </div>
                  <blockquote>
                    <p>
                      Teknoparklarda veya bilişim vadisindeki arge merkezimizde
                      yapmış olduğumuz çalışmalar neticesinde açığa çıkan ürünün
                      tedarikçisi olduğumuz müşterilerimizin ürünleriyle beraber
                      çalışacak numune ve protatipleri kurup yaşlanma ve ürün
                      performanslarını izleyeceğimiz ve raporlayabileceğimiz
                      protatipleri teknolojinin tüm imkanlarını kullanarak kendi
                      bünyemizde yapmaktayız.
                    </p>
                  </blockquote>
                </div>
              </div>
              <a id="production"></a>
            </div>
            <div class="service-block">
              <div class="text-content">
                <div class="left-img">
                  <img src="images/uretim2.jpeg" class="img-size" />
                </div>
                <div class="text-size-right">
                  <div class="title-content">
                    <h3>Üretim</h3>
                  </div>
                  <blockquote>
                    <p>
                      Gebze hasköy sanayi sitesinde 1.500 metre karelik modern
                      üretim tesisimizde farklı miktar ve özellikteki
                      taleplerinizi hızlı ve kaliteli üretebilecek esnek üretim
                      süreçlerini uyguluyoruz gelişmiş makina
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>
    
    <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
  },
};
</script>
    
    <style scoped>
.img-size {
  width: 450px;
  height: 300px;
}
.text-size-left {
  width: 50%;
  float: left;
  padding-right: 50px;
}
.text-size-right {
  width: 50%;
  float: right;
  padding-right: 50px;
}
.banner-title{
  background-position-y: 76%;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
 
}

@media (max-width: 768px) {

}

@media (max-width: 575px) {
  .img-size {
  width: 250px;
  height: 150px;
  margin-left: 100px;
}
.text-size-left {
  width: 100%;
  padding-right: 0px;
}
.text-size-right {
  width:100%;
  padding-right: 0px;
}
}

@media only screen and (max-width: 400px) and (orientation: portrait) { 
  .img-size {
  width: 250px;
  height: 150px;
  margin-left: 50px;
}
.text-size-left {
  width: 100%;
  padding-right: 0px;
}
.text-size-right {
  width:100%;
  padding-right: 0px;
}
}
</style>