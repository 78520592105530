<template>
    <Navbar />
    <ScrollButton />
  
    <section class="banner-title" style="background-image: url(images/auto.png)">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="title-column col-md-6 col-sm-6 col-xs-12">
            <h1>Otomasyon Sistemleri</h1>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <ul class="bread-crumb clearfix">
              <li>
                <a href="#">Anasayfa</a>
              </li>
              <li class="active">Otomasyon Sistemleri</li>
            </ul>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Banner text end -->
    </section>
    <section class="page-section bg-light" id="portfolio">
      <div class="container">
        <div class="text-uppercase line-wrapper">
          <div class="line"></div>
          <div class="text">Pergola Otomasyon Sistemleri</div>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="text-center">
            <p>
                Müşterimizin dış mekanında maksimum konfor ve kullanım kolaylığı sağlamak için bir araya geldik. 
                Pergola otomasyon sistemimiz, 
                dış mekan yaşamınızı tamamen değiştirirken estetik ve işlevsellik arasında mükemmel bir denge kuruyor.
            </p>
            <p><b>Kolay Kullanım ve Kontrol</b></p>
            <p>
                Otomasyon sistemimiz, pergola yapılarınızı hava koşullarına otomatik olarak uyum sağlayacak şekilde kontrol etmenizi sağlar. 
                Akıllı sensörler ve kullanıcı dostu arayüzü sayesinde, dış mekanınızı kolayca yönetebilir ve 
                istediğiniz konfor seviyesini elde edebilirsiniz. Uzaktan kumanda veya mobil uygulama aracılığıyla kolayca erişilebilir.
            </p>
  
            <p><b>Güvenlik ve Dayanıklılık</b></p>
            <p>
                Gelişmiş güvenlik özellikleri ve dayanıklı malzemelerle donatılmış olan otomasyon sistemimiz, 
                pergola yapılarınızın uzun ömürlü olmasını sağlar. 
                Her hava koşulunda güvenli ve sağlam bir kullanım sunar, böylece dış mekan keyfiniz kesintisiz devam eder.
            </p>
  
            <p><b>Estetik Uyum</b></p>
            <p>
                Otomasyon sistemimiz, pergola yapılarınızın estetik görünümünü bozmadan entegre edilir. 
                Zarif tasarımıyla dış mekanınıza modern bir dokunuş katar ve mekanınızın güzelliğini korur.
            </p>
  
            <p><b>Bizimle İleriye Doğru</b></p>
            <p>
                Müşterilerimizin ihtiyaçlarına yönelik özel çözümler sunmaktan memnuniyet duyarız. 
                Pergola otomasyon sistemlerimiz hakkında daha fazla bilgi almak ve
                 projenizi hayata geçirmek için bizimle iletişime geçin. Size mükemmel bir dış mekan deneyimi sunmak için buradayız
            </p>
          </div>
        </div>
        <div class="row">
          <div class="text-uppercase line-wrapper mt-5">
            <div class="text">Galeri</div>
            <div class="line"></div>
          </div>
          <div
            class="col-lg-3 col-sm-6 mb-3"
            v-for="(item, index) in pergolas"
            :key="index"
          >
            <!-- Portfolio item 1-->
            <div class="portfolio-item">
              <a
                class="portfolio-link"
                data-bs-toggle="modal"
                :href="'#portfolioModal' + index"
              >
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="plus" />
                  </div>
                </div>
                <img class="img-fluid" :src="item.logo" :alt="item.baslik" />
              </a>
              <!-- <div class="portfolio-caption">
                                  <div class="portfolio-caption-heading">Threads</div>
                                  <div class="portfolio-caption-subheading text-muted">Illustration</div>
                              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <div
      class="portfolio-modal modal fade"
      v-for="(item, index) in pergolas"
      :key="index"
      :id="'portfolioModal' + index"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="close-modal" data-bs-dismiss="modal">
            <img src="images/close-icon.svg" alt="Close modal" />
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="modal-body">
                  <!-- Project details-->
                  <img
                    class="img-fluid d-block mx-auto"
                    :src="item.logo"
                    alt="Otomasyon Sistemleri"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import { ref, onMounted } from "vue";
  import { firestore } from "@/firebase/config";
  import { query, collection, getDocs, where } from "firebase/firestore";
  
  import Navbar from "@/components/Navbar-2.vue";
  import ScrollButton from "@/components/ScrollButton.vue";
  import Footer from "@/components/Footer.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  
  export default {
    components: {
      Navbar,
      ScrollButton,
      Footer,
      FontAwesomeIcon,
    },
    setup() {
      const pergolas = ref([]);
  
      onMounted(async () => {
        let q = query(
          collection(firestore, "pergola"),
          where("durum", "==", true)
        );
  
        const querySnapshot = await getDocs(q);
  
        const veri = [];
  
        querySnapshot.forEach((doc) => {
          veri.push({ ...doc.data(), id: doc.id });
        });
  
        pergolas.value = veri;
      });
  
      return {
        pergolas,
      };
    },
  };
  </script>
    
    <style scoped>
  </style>