<template>
  <div class="carousel slide" id="mySlide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="0"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="ilk slayt"
      ></button>

      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="1"
        class="carousel-indicator-item"
        aria-label="ikinci slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="2"
        class="carousel-indicator-item"
        aria-label="üçüncü slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="3"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="dördüncü slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="4"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="beşinci slayt"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="images/slider-intek3.png"
          class="d-block w-100"
          alt="slide-1"
        />
        <div class="carousel-caption d-none d-md-block">
          <!-- <h2>First slide label</h2>
        <p>Some representative placeholder content for the first slide.</p> -->
        </div>
      </div>
      <div class="carousel-item">
        <img src="images/sensor2.png" class="d-block w-100" alt="slide-2" style="height: 700px;"/>
        <div class="carousel-caption d-none d-md-block">
          <!-- <h2>First slide label</h2>
        <p>Some representative placeholder content for the first slide.</p> -->
        </div>
      </div>
      <div class="carousel-item">
        <img src="images/kartlar/slider3.jpg" class="d-block w-100" alt="slide-3" />
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item">
        <img src="images/kartlar/slider.jpg" class="d-block w-100" alt="slide-4" style="height: 700px;"/>
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item">
        <img src="images/kartlar/slider2.jpeg" class="d-block w-100" alt="slide-4" style="height: 700px;"/>
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
    </div>

    <button
      type="button"
      class="carousel-control-prev"
      data-bs-target="#mySlide"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Önceki</span>
    </button>
    <button
      type="button"
      class="carousel-control-next"
      data-bs-target="#mySlide"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Sonraki</span>
    </button>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
</style>
  
  