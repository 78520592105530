<template>
  <footer id="footer" class="footer mt-auto">
    <div class="footer-main">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 footer-widget">
            <img src="images/logo/logo-trans1.png" alt="İntek Teknoloji" />
            <div class="footer-social">
              <ul>
                <li>
                  <a href="https://www.linkedin.com/company/intek-bilisim-teknoloji/" target="_blank" 
                    ><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/intekteknoloji/"  target="_blank"  
                    ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
                </li>
              </ul>
            </div>
            <!-- Footer social end -->
          </div>
          <!-- Col end -->

          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 none footer-widget">
            <h3 class="widget-title">İletişim Bilgileri</h3>
            <ul class="list-arrow">
              <li>
                <p><b>Telefon:</b> 0555 887 1241</p>
              </li>
              <li>
                <p><b>Email:</b>info@intekbilisim.com</p>
              </li>
              <li>
                <p><b>Adres:</b> Muallimköy Mh. Deniz Cd.<br />Bilişim Vadisi 2B Blok D:110</p>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-widget mt-5 mt-md-0 none">
            <h3 class="widget-title">Hakkımızda</h3>
            <ul class="list-arrow">
              <li>
                <p> Bir aile şirketi olarak 1996 yılında faaliyetlerine
                      başlayan firmamız 2004 yılından beri intek teknoloji
                      olarak elektronik ve otomasyon guruplarında üretim taahhüt
                      faliyetlerini sürdürmektedir. </p>
                
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-widget mt-5 mt-md-0 none">
            <h3 class="widget-title">Hizmetlerimiz</h3>
            <ul class="list-arrow">
              <li>
                <router-link to="/services" aria-current="page"
                  >ARGE</router-link
                >
              </li>
              <li>
                <router-link to="/services" aria-current="page"
                  >Tasarım</router-link
                >
              </li>
              <li>
                <router-link to="/services" aria-current="page"
                  >Numune ve Prototip</router-link
                >
              </li>
              <li>
                <router-link to="/contact" aria-current="page"
                  >İletişim</router-link
                >
              </li>
            </ul>
          </div>
          <div class="mobile-footer">
            <h3 class="widget-title">İletişim</h3>
            <ul class="list-arrow">
              <li>
                <p>Telefon No: 555555555</p>
              </li>
              <li>
                <p>Adres:</p>
              </li>
              <li>
                <p>Email:</p>
              </li>
            </ul>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div
          class="row justify-content-center align-items-center justify-content-center"
        >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p class="copyright">
              İntek Teknoloji © 2024. Tüm Hakları Saklıdır.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer main end -->
  </footer>
</template>
    
    <script>
    import { ref, onMounted } from "vue";
import getUser from "@/composables/getUser";

export default {
  setup() {
    const kId = ref("");
    const { kullanici } = getUser();

    onMounted(() => {
      if (kullanici.value) {
        console.log(kullanici.value.uid);
      } else {
        console.log(kullanici.value);
      }

      // console.log(kullanici.value.uid);
    });
    return { kullanici, kId };
  },
};
</script>
    
    <style scoped>

</style>