<template>
  <div class="container-fluid bg-white sticky-top">
    <div class="container">
      <nav class="navbar navbar-expand-lg bg-white navbar-light py-2 py-lg-0">
        <router-link to="/" class="navbar-brand">
          <img
            class="img-fluid animate"
            src="images/logo/logo-trans1.png"
            alt="Logo"
          />
        </router-link>
        <button
          type="button"
          class="navbar-toggler ms-auto me-0"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto">
            <router-link to="/" class="nav-item nav-link">Anasayfa</router-link>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                >Kurumsal</a
              >
              <div class="dropdown-menu bg-light rounded-0 m-0">
                <router-link to="/about" class="dropdown-item"
                  >Biz Kimiz</router-link
                >
                <router-link to="/values" class="dropdown-item"
                  >Değerlerimiz</router-link
                >
                <router-link to="/what-we-do" class="dropdown-item"
                  >Ne Yapıyoruz</router-link
                >
                <router-link to="/quality-policy" class="dropdown-item"
                  >Kalite Politikamız</router-link
                >
                <router-link to="" class="dropdown-item"
                  >Sertifikalarımız</router-link
                >
                <router-link to="/news" class="dropdown-item"
                  >Haberler</router-link
                >
              </div>
            </div>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                >Hizmetlerimiz</a
              >
              <div class="dropdown-menu bg-light rounded-0 m-0">
                <router-link
                  to="/services"
                  @click="scrollToArge"
                  class="dropdown-item"
                  >ARGE</router-link
                >
                <router-link
                  to="/services"
                  @click="scrollToDesign"
                  class="dropdown-item"
                  >Tasarım</router-link
                >
                <router-link
                  to="/services"
                  @click="scrollToPrototype"
                  class="dropdown-item"
                  >Numune ve Prototip</router-link
                >
                <router-link
                  to="/services"
                  @click="scrollToProduction"
                  class="dropdown-item"
                  >Üretim</router-link
                >
              </div>
            </div>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                >Üretim</a
              >
              <div class="dropdown-menu bg-light rounded-0 m-0">
                <router-link to="/automation-system" class="dropdown-item"
                  >Otomasyon Sistemleri</router-link
                >
                <router-link to="/command-control-cards" class="dropdown-item"
                  >Kumanda ve Kontrol Kartları</router-link
                >
                <router-link to="/cable-bundling" class="dropdown-item"
                  >Kablo Gruplama</router-link
                >
                <router-link to="/sensor-production" class="dropdown-item"
                  >Sensör Üretimi</router-link
                >
                <router-link to="/prototype-production" class="dropdown-item"
                  >Prototip Üretimi</router-link
                >
              </div>
            </div>
            <router-link to="/arge" class="nav-item nav-link">ARGE</router-link>
            <router-link to="/contact" class="nav-item nav-link"
              >İletişim</router-link
            >
          </div>
        </div>
      </nav>
    </div>
  </div>
  
</template>

<script>
export default {
  methods: {
    scrollToArge() {
      const element = document.getElementById("arge");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToDesign() {
      const element = document.getElementById("design");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToPrototype() {
      const element = document.getElementById("prototype");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToProduction() {
      const element = document.getElementById("production");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.sticky-top {
  top: -150px;
  transition: 0.5s;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
</style>