<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <router-link to="/admin" class="navbar-brand text-uppercase">
      <img
        src="images/logo/logo-trans1.png"
        alt=""
        width="80"
        height="80"
        class="d-inline-block align-text-top"
      />
      Admin
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li v-if="yetki == 'yonetici'" class="nav-item">
          <router-link
            to="/admin/dashboard"
            class="nav-link active"
            aria-current="page"
            >Anasayfa</router-link
          >
        </li>
        <li v-if="yetki == 'yonetici'" class="nav-item">
          <router-link
            to="/admin/profile"
            class="nav-link active"
            aria-current="page"
            >Profilim</router-link
          >
        </li>
        <li v-if="yetki == 'yonetici'" class="nav-item dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Kurumsal<font-awesome-icon :icon="['fas', 'caret-down']"
          /></a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="/admin/news-list" class="dropdown-item"
                >Haberler</router-link
              >
            </li>
            <li>
              <router-link to="/admin/certificate-list" class="dropdown-item"
                >Sertifikalar</router-link
              >
            </li>
            <li>
              <router-link to="/admin/reference-list" class="dropdown-item"
                >Referanslar</router-link
              >
            </li>
          </ul>
        </li>

        <li v-if="yetki == 'yonetici'" class="nav-item dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Üretim<font-awesome-icon :icon="['fas', 'caret-down']"
          /></a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="/admin/automation-list" class="dropdown-item"
                >Otomasyon Sistemleri</router-link
              >
            </li>
            <li>
              <router-link
                to="/admin/command-control-list"
                class="dropdown-item"
                >Kumanda Ve Kontrol Kartları</router-link
              >
            </li>
            <li>
              <router-link to="/admin/cable-group-list" class="dropdown-item"
                >Kablo Grupları</router-link
              >
            </li>
            <li>
              <router-link to="/admin/sensor-list" class="dropdown-item"
                >Sensör Üretimi</router-link
              >
            </li>
            <li>
              <router-link to="/admin/prototype-list" class="dropdown-item"
                >Prototip Üretimi</router-link
              >
            </li>
            <!-- Diğer dropdown öğelerini buraya ekleyebilirsiniz -->
          </ul>
        </li>
        <li v-if="yetki == 'yonetici'" class="nav-item dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Otomasyon Sistem Alt Başlık<font-awesome-icon :icon="['fas', 'caret-down']"
          /></a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="/admin/automation-panel-list" class="dropdown-item"
                >Otomasyon Panoları</router-link
              >
            </li>
            <li>
              <router-link to="/admin/garbage-list" class="dropdown-item"
                >Çöp Şutu</router-link
              >
            </li>
            <li>
              <router-link to="/admin/tea-list" class="dropdown-item"
                >Çay Makinesi</router-link
              >
            </li>
            <li>
              <router-link to="/admin/pergola-list" class="dropdown-item"
                >Pergola</router-link
              >
            </li>
          </ul>
        </li>
        <!-- dropdown Eklenicek -->

        <li v-if="yetki == 'yonetici'" class="nav-item">
          <router-link
            to="/admin/arge-list"
            class="nav-link active"
            aria-current="page"
            >Arge</router-link
          >
        </li>
      </ul>
      <ul
        v-if="!kullanici"
        class="navbar-nav me-auto mb-2 mb-lg-0 text-warning"
      >
        <li class="nav-item">İşlemler için doğru yerdesiniz</li>
      </ul>
      <div v-if="kullanici" class="text-end">
        <span class="me-2 text-warning">Merhaba {{ kullaniciAd }}</span>

        <button
          @click="handleLogout"
          type="button"
          class="btn btn-outline-danger me-2"
        >
          Çıkış
        </button>
        <router-link to="/admin/addmember" class="btn btn-warning" type="submit"
          >Üye Ekle</router-link
        >
      </div>

      <div v-if="!kullanici" class="d-flex">
        <router-link
          to="/admin/login"
          class="btn btn-outline-light me-2"
          type="submit"
          >Giriş</router-link
        >
      </div>
    </div>
  </nav>
</template>
  
  <script>
import { ref, onMounted, onBeforeUpdate } from "vue";
import getUser from "../composables/getUser";

import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";

import getYetkili from "../composables/getYetkili";
import { auth, firestore } from "@/firebase/config";
import { getDocs, query, collection, where, limit } from "firebase/firestore";

export default {
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    openDropdown() {
      this.dropdownOpen = true;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
  },
  setup() {
    const { kullanici } = getUser();
    const kullaniciAd = ref("");
    const router = useRouter();
    const { hataLogout, logout } = useLogout();
    const { yetki, yetkiKontrol } = getYetkili();

    onBeforeUpdate(async () => {
      if (kullanici.value) {
        kullaniciAd.value = kullanici.value.displayName;

        await yetkiKontrol(kullanici.value.uid);
      }
    });

    onMounted(async () => {});

    const handleLogout = async () => {
      await logout();

      router.push({ name: "HomeView" });
    };

    return { kullanici, kullaniciAd, handleLogout, yetki };
  },
};
</script>
  
  <style scoped>
ul.navbar-nav > li > a {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.84) !important; /*navbar color*/
  text-rendering: optimizeLegibility;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  font-size: 14px;
  margin: 0;
  line-height: 40px;
  padding: 30px 0;
  transition: 350ms;
}
ul.navbar-nav > li {
  padding: 0 10px;
  position: relative;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
</style>