<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="
      background-image: url(images/bv-img.jpeg);
      background-position: center;
      background-size: cover;
    "
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Kalite Politikamız</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Kalite Politikamız</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-container">
    <div class="auto-container">
      <div class="row">
        <div
          class="content-side pull-left col-lg-12 col-md-8 col-sm-12 col-xs-12"
        >
          <div class="blog-section">
            <div class="news-block-detail">
              <div class="title-content">
                <h3>Kalite Politikamız</h3>
              </div>
              <div class="text-content">
                <div alt>
                 
                    <p>
                      Biz, Intek Teknoloji olarak, müşteri memnuniyetini ön
                      planda tutarak, elektronik ve otomasyon sektöründe en iyi
                      kalite standartlarını sağlamayı taahhüt ediyoruz.
                      Firmamızın kalite politikası aşağıdaki ilkelerle
                      belirlenmiştir:
                    </p>

                    <ol type="1" class="mt-2">
                      <li>
                        <b>Müşteri Memnuniyeti </b>
                        <p>
                          Müşterilerimizin ihtiyaçlarını anlamak ve onların
                          beklentilerini aşmak için çaba gösteriyoruz. Müşteri
                          geri bildirimlerini dikkate alarak sürekli iyileştirme
                          yapmayı hedefliyoruz.
                        </p>
                      </li>
                      <li>
                        <b>Yüksek Kalite Standartları </b>
                        <p>
                          Ürünlerimizin ve hizmetlerimizin kalitesini sürekli
                          olarak iyileştirmek için kalite standartlarını en üst
                          seviyede tutuyoruz. Sürekli eğitim ve gelişimle
                          personelimizin kalite bilincini artırıyoruz.
                        </p>
                      </li>
                      <li>
                        <b>İnovasyon ve Teknoloji </b>
                        <p>
                          Sürekli olarak sektördeki teknolojik gelişmeleri takip
                          ediyor ve bunları ürün ve hizmetlerimize entegre
                          ediyoruz. Ar-Ge faaliyetlerimize yatırım yaparak
                          yenilikçi çözümler üretiyoruz.
                        </p>
                      </li>
                      <li>
                        <b>Çevreye Duyarlılık </b>
                        <p>
                          Ürünlerimizin ve üretim süreçlerimizin çevresel
                          etkilerini en aza indirmek için çevre yönetim
                          sistemlerini uyguluyoruz. Sürdürülebilirlik ilkelerine
                          bağlı kalarak doğal kaynakları koruyoruz.
                        </p>
                      </li>
                      <li>
                        <b>Sürekli İyileştirme</b>
                        <p>
                          İş süreçlerimizi sürekli olarak gözden geçiriyor ve
                          iyileştirme fırsatlarını belirliyoruz. Veri analizi ve
                          performans ölçümü ile sürekli iyileştirme kültürünü
                          destekliyoruz.
                        </p>
                      </li>
                      <li>
                        <b>Adil ve Eşitlikçi Yaklaşım</b>
                        <p>
                          Personelimizin eğitim, kariyer gelişimi ve iş
                          deneyiminde adil ve eşitlikçi bir yaklaşımı
                          benimsiyoruz. Çeşitlilik ve farklı bakış açılarını
                          değerli buluyoruz.
                        </p>
                      </li>
                    </ol>
                    <p>
                      Bu kalite politikası, Intek Teknoloji olarak kalite
                      yönetim sistemimizin temelidir. Müşterilerimize değer ve
                      güven sağlamak için bu ilkeler doğrultusunda çalışmaya
                      devam edeceğiz.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>
  
  <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
  },
};
</script>
  
  <style>
</style>