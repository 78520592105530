<template>
    <AdminNavbar />
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <button
        type="button"
        class="btn-close modal__close"
        aria-label="Close"
        @click="showModal = false"
      ></button>
  
      <span class="modal__title text-danger">Hata Mesajı</span>
      <div class="modal-content">
        <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
        <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
      </div>
    </vue-final-modal>
    <loading
      v-model:active="isLoading"
      :is-full-page="true"
      loader="bars"
      color="#ffc300"
    ></loading>
  
    <div class="container">
      <main>
        <div class="pt-5 text-center">
          <div class="alert alert-dark" role="alert">
            <h2>Çay Makinesi Resim Güncelle</h2>
            <p class="lead">
              <router-link to="/admin/tea-list"
                >Çay Makinesi Resim Listesi içn Tıklayınız</router-link
              >
            </p>
          </div>
        </div>
  
        <div class="row g-5">
          <div class="col-md-8 col-lg-8">
            <h4 class="mb-3">Çay Makinesi Resim Bilgileri</h4>
            <div class="d-flex justify-content-between">
              <p>Eklenen tarihi: {{ tarih }}</p>
            </div>
            <form class="needs-validation" @submit.prevent="handleSubmit">
              <div class="row g-3">
                <div class="col-sm-6">
                  <label for="baslik" class="form-label">Başlık</label>
                  <input
                    type="text"
                    class="form-control"
                    id="baslik"
                    placeholder=""
                    v-model="baslik"
                  />
                </div>
  
                <div class="col-sm-6">
                  <label for="icerik" class="form-label">İçerik</label>
                  <input
                    type="text"
                    class="form-control"
                    id="icerik"
                    placeholder=""
                    v-model="icerik"
                  />
                </div>
                <div class="col-6">
                  Durum <br />
                  <div>
                    <input
                      type="radio"
                      id="aktif"
                      :value="true"
                      name="durumRadio"
                      v-model="durum"
                      :checked="durum == true"
                    />
                    <label for="aktif" class="form-label"> Aktif </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="pasif"
                      :value="false"
                      name="durumRadio"
                      v-model="durum"
                      :checked="durum == false"
                    />
                    <label for="pasif" class="form-label"> Pasif </label>
                  </div>
                </div>
              </div>
  
              <hr class="my-4" />
  
              <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
                Güncelle
              </button>
            </form>
          </div>
  
          <div class="col-md-4 col-lg-4 order-md-last">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span>Otomasyon Resim</span>
            </h4>
            <img :src="logo" class="rounded img-thumbnail" ref="resim" />
          </div>
        </div>
      </main>
    </div>
    <AdminFooter />
  </template>
    
    <script>
  import { ref, onMounted } from "vue";
  import { firestore } from "@/firebase/config";
  import { useRouter, useRoute } from "vue-router";
  import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
  
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import moment from "moment";
  
  import { VueFinalModal, ModalsContainer } from "vue-final-modal";
  import AdminFooter from "@/components/AdminFooter.vue";
  import AdminNavbar from "@/components/AdminNavbar.vue";
  
  export default {
    components: {
      Loading,
      VueFinalModal,
      ModalsContainer,
      AdminFooter,
      AdminNavbar,
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const showModal = ref(false);
      const isLoading = ref(true);
      const teaId = ref(route.params.teaId);
      const tarih = ref("");
  
      const olusturulmaTarihi = ref("");
      const icerik = ref("");
      const baslik = ref("");
      const durum = ref(false);
      const logo = ref("");
      const fileHata = ref(null);
      const hataMesaj = ref("");
  
  
      const bilgiKontrol = ref(false);
      moment.locale("tr");
  
      onMounted(async () => {
        const docRef = doc(firestore, "cay", teaId.value);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
          icerik.value = docSnap.data().icerik;
          baslik.value = docSnap.data().baslik;
          logo.value = docSnap.data().logo;
          durum.value = docSnap.data().durum;
  
          isLoading.value = false;
          tarih.value = moment(olusturulmaTarihi.value).fromNow();
  
          console.log(docSnap.data());
        } else {
          console.log("Veriye erişilemedi");
        }
      });
  
      const handleSubmit = async () => {
        isLoading.value = true;
        const myCollection = collection(firestore, "cay");
        const myDocRef = doc(myCollection, teaId.value);
        try {
          const data = {
            icerik: icerik.value,
            baslik: baslik.value,
            logo: logo.value,
            durum: durum.value === true ? true : false,
          };
          await updateDoc(myDocRef, data);
          router.push("/admin/tea-list");
        } catch (error) {
          hataMesaj.value = error.message;
          isLoading.value = false;
          showModal.value = true;
        }
      };
  
      return {
        tarih,
        teaId,
        olusturulmaTarihi,
        icerik,
        baslik,
        logo,
        bilgiKontrol,
        isLoading,
        handleSubmit,
        hataMesaj,
        fileHata,
        durum,
        showModal,
      };
    },
  };
  </script>
    
  <style scoped>
  </style>