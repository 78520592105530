// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDAJkmc_GAQsjbB3uQeaPAu9oNHl3G6R-U",
  authDomain: "intek-bilisim-project.firebaseapp.com",
  projectId: "intek-bilisim-project",
  storageBucket: "intek-bilisim-project.appspot.com",
  messagingSenderId: "247177595417",
  appId: "1:247177595417:web:a51a98b19092ccd924f888",
  measurementId: "G-8QCLQ97RPM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth(app);
const firestore=getFirestore(app);
const storage=getStorage(app);
const analytics = getAnalytics(app);

export {auth,firestore,storage}