<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/color.png)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Değerlerimiz</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Değerlerimiz</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-container">
    <div class="auto-container">
      <div class="row">
        <div
          class="content-side pull-left col-lg-12 col-md-8 col-sm-12 col-xs-12"
        >
          <div class="blog-section">
            <div class="news-block-detail">
              <div class="title-content">
                <h3>Değerlerimiz</h3>
              </div>
              <div class="text-content">
                <div class="right-img">
                  <img
                    src="images/prototip.jpg"
                    class="value-img"
                  />
                </div>
                <div class="left-text">
                  <blockquote>
                    <p>
                      Sektörde yılların tecrübesini modern tasarım ve üretim
                      teknikleri ile birleştirerek dünyadaki teknolojik
                      gelişmeleri algılayıp uygulayarak ürünlerimizi ve
                      projelerimizi yurt içi ve yurt dışı pazarlarda görmekten
                      gurur duyuyoruz.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</template>
  
  <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer
  },
};
</script>
  
  <style>
</style>