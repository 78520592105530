<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/automation.png)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Sensör Üretimi</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Sensör Üretimi</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-section" id="portfolio">
    <div class="container">
      <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">Profesyonel Sıcaklık Sensörleri</div>
        <div class="line"></div>
      </div>
      <div class="row">
        <div class="text-center">
          <p><b>Müşteriye Özel Çözümler</b></p>
          <p>
            Otomasyon sistemlerinde güvenilir ve hassas sıcaklık sensörleri
            kritik öneme sahiptir. Firmamız, özel ihtiyaçlarınıza uygun olarak
            çeşitli tiplerde ve farklı uçlarla donatılmış, özelleştirilebilir
            sıcaklık sensörleri sunmaktadır.
          </p>
          <p><b>Farklı Tipler, Farklı Uçlar, Farklı Kablolar</b></p>
          <p>
            Müşterilerimizin benzersiz gereksinimlerini karşılamak için geniş
            bir ürün yelpazesine sahibiz. Endüstriyel tesislerden laboratuvar
            ortamlarına kadar çeşitli uygulamalara uygun olan sıcaklık
            sensörlerimiz, farklı tiplerde (termistör, termokupl , p100 , pt1000
            vb.), farklı uçlarda (uçlu, uçsuz vb.) ve farklı kablo boylarında
            sunulmaktadır.
          </p>

          <p><b>Özel Tasarım ve Üretim</b></p>
          <p>
            Firmamız, müşterilerimizin spesifikasyonlarına uygun olarak özel
            tasarlanmış ve üretilmiş sıcaklık sensörleri sunmaktadır. Size en
            uygun çözümü sağlamak için uzman ekibimiz, ihtiyaçlarınızı analiz
            eder ve özelleştirilmiş bir ürün tasarlar.
          </p>

          <p><b>Yüksek Kalite ve Güvenilirlik</b></p>
          <p>
            Tüm ürünlerimiz, endüstri standartlarına uygun olarak yüksek kalite
            ve dayanıklılık standartlarını karşılamaktadır. Müşteri
            memnuniyetini ön planda tutarak, sürekli kalite kontrolü ve
            testlerle ürünlerimizin güvenilirliğini sağlıyoruz.
          </p>

          <p><b>Sizin İçin Buradayız</b></p>
          <p>
            Firmamız, uzmanlık, kalite ve müşteri odaklılık konularında
            liderdir. Sizin işiniz, verimliliğiniz ve güvenliğiniz için en uygun
            çözümleri sunmak için buradayız. İhtiyaçlarınızı karşılamak için
            bize bugün ulaşın ve özel sıcaklık sensörleri hakkında daha fazla
            bilgi edinin.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="text-uppercase line-wrapper mt-5">
          <div class="text">Galeri</div>
          <div class="line"></div>
        </div>
        <div
          class="col-lg-3 col-sm-6 mb-3"
          v-for="(item, index) in sensorList"
          :key="index"
        >
          <!-- Portfolio item 1-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              :href="'#portfolioModal' + index"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <font-awesome-icon icon="plus" />
                </div>
              </div>
              <img class="img-fluid" :src="item.logo" :alt="item.baslik" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
  <div
    class="portfolio-modal modal fade"
    v-for="(item, index) in sensorList"
    :key="index"
    :id="'portfolioModal' + index"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="close-modal" data-bs-dismiss="modal">
          <img src="images/close-icon.svg" alt="Close modal" />
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="modal-body">
                <!-- Project details-->
                <img
                  class="img-fluid d-block mx-auto"
                  :src="item.logo"
                  alt="Otomasyon Sistemleri"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { query, collection, getDocs, where } from "firebase/firestore";

import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
    FontAwesomeIcon,
  },
  setup() {
    const sensorList = ref([]);

    onMounted(async () => {
      let q = query(
        collection(firestore, "sensor"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      sensorList.value = veri;
    });

    return {
      sensorList,
    };
  },
};
</script>

<style scoped>
</style>