<template>
  <Navbar />
  <ScrollButton />

  <section class="banner-title" style="background-image: url(images/arge3.png)">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Prototip Üretimi</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Prototip Üretimi</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-section bg-light" id="portfolio">
    <div class="container">
      <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">Prototip</div>
        <div class="line"></div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-sm-6 mb-3"
          v-for="(item, index) in pros"
          :key="index"
        >
          <!-- Portfolio item 1-->
          <div class="portfolio-item">
            <a
              class="portfolio-link"
              data-bs-toggle="modal"
              :href="'#portfolioModal' + index"
            >
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <font-awesome-icon icon="plus" />
                </div>
              </div>
              <img class="img-fluid" :src="item.logo" :alt="item.baslik" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
  <div
    class="portfolio-modal modal fade"
    v-for="(item, index) in pros"
    :key="index"
    :id="'portfolioModal' + index"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="close-modal" data-bs-dismiss="modal">
          <img src="images/close-icon.svg" alt="Close modal" />
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="modal-body">
                <!-- Project details-->
                <img
                  class="img-fluid d-block mx-auto"
                  :src="item.logo"
                  alt="Otomasyon Sistemleri"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { query, collection, getDocs, where } from "firebase/firestore";

import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
    FontAwesomeIcon,
  },
  setup() {
    const pros = ref([]);

    onMounted(async () => {
      let q = query(
        collection(firestore, "prototip"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      pros.value = veri;
    });

    return {
      pros,
    };
  },
};
</script>

<style scoped>
</style>