<template>
    <AdminNavbar/>
    <Loading
      v-model:active="isLoading"
      :is-full-page="true"
      color="#ffc300"
      loader="bars"
    />
    <div class="login-form">
      <main class="form-signin">
        <form @submit.prevent="handleSubmit">
          <h1 class="h3 mb-5 fw-bold text-center">İntek Giriş</h1>
  
          <div class="form-floating">
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="mail@ornek.com"
              v-model="email"
            />
            <label for="floatingInput">Email Adresiniz</label>
          </div>
          <br />
          <div class="form-floating">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Parolanızı Giriniz"
              v-model="parola"
            />
            <label for="floatingPassword">Parola</label>
          </div>
          <vue-recaptcha sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" @verify="verifyMethod"></vue-recaptcha>
          
          <button :disabled="buttonDisabled" class="my-3 w-100 btn btn-lg btn-primary" type="submit">
            Giriş
          </button>
          <router-link to="/"><i class="bi bi-arrow-left"></i> Anasayfa'ya Dön </router-link>
          <hr />
        
          <div v-if="hata" class="text-danger text-center">{{ hata }}</div>
        </form>
      </main>
    </div>
    <AdminFooter/>
  </template>
  
  <script>
  import useLogin from "../../composables/useLogin";
  import { ref } from "vue";
  import { firestore } from "@/firebase/config";
  import { collection, query, where, getDocs } from "firebase/firestore";
  import { useRouter } from "vue-router";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import {VueRecaptcha} from 'vue-recaptcha'
  import AdminFooter from '@/components/AdminFooter.vue'
  import AdminNavbar from "@/components/AdminNavbar.vue";
  
  export default {
    components: {
      Loading,
      VueRecaptcha,
      AdminFooter,
      AdminNavbar
    },
    setup() {
      const { hataLogin, login } = useLogin();
      const email = ref("");
      const parola = ref("");
      const hata = ref(null);
      const router = useRouter();
      const isLoading = ref(false);
      const buttonDisabled=ref(true);
  
      const handleSubmit = async () => {
        isLoading.value = true;
  
        hata.value = null;
        if (email.value !== "" && parola.value !== "") {
          const q = query(
            collection(firestore, "kullanicilar"),
            where("durum", "==", false),
            where("email", "==", email.value)
          );
  
          const querySnopshot = await getDocs(q);
  
          if (querySnopshot.empty) {
            const res = await login(email.value, parola.value);
  
            if (!hataLogin.value) {
              router.push({ name: "Dashboard" });
              // console.log("Giriş yapıldı", res.user);
            } else {
              hata.value = hataLogin.value;
              isLoading.value=false;
            }
          } else {
            hata.value = "Üyeliğiniz askıya alınmış durumda";
            email.value = "";
            parola.value = "";
            isLoading.value=false;
          }
        } else {
          hata.value = "Alanlar boş geçilmez";
          isLoading.value=false;
        }
      };
      const verifyMethod=()=>{
        buttonDisabled.value=false;
      }
      return { email, parola, handleSubmit, hata, isLoading,buttonDisabled ,verifyMethod};
    },
  };
  </script>
  
  <style scoped>
  html,
  body {
    height: 100%;
  }
  
  body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .login-form {
    margin-top: 200px;
  }
  .form-signin {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    margin: auto;
  }
  </style>
  