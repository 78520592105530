<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <Loading
    v-model:active="isLoading"
    :is-full-page="true"
    color="#ffc300"
    loader="spiners"
  />
  <div class="signup-form">
    <main class="form-signup mt-2">
      <form @submit.prevent="handleSubmit">
        <h3 class="h3 mb-3 fw-bold text-center">Üyelik Oluştur</h3>
        <p class="text-muted mb-1 fw-normal">
          Lütfen bilgilerinizi girerek üyeliğinizi oluşturunuz
        </p>
        <div class="mb-2">
          <label for="emailAdres" class="form-label text-start"
            >Email Adresiniz</label
          >
          <input
            type="email"
            class="form-control"
            id="emailAdres"
            v-model="email"
            placeholder="email@ornek.com"
          />
        </div>

        <div class="mb-2">
          <label for="kullaniciAd" class="form-label text-start"
            >Kullanıcı Adınız</label
          >
          <input
            type="text"
            class="form-control"
            id="kullaniciAd"
            v-model="kullaniciAd"
            placeholder="Kullanıcı Adınızı giriniz"
          />
        </div>

        <div class="row mb-1">
          <div class="col-md-6">
            <label for="userName" class="form-label text-start">İsim</label>
            <input
              type="text"
              class="form-control"
              id="userName"
              placeholder="İsim giriniz"
              v-model="isim"
            />
          </div>
          <div class="col-md-6">
            <label for="surname" class="form-label text-start">Soyİsim</label>
            <input
              type="text"
              class="form-control"
              id="surname"
              placeholder="Soyİsim giriniz"
              v-model="soyisim"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Yetki</label>
            <div class="col-3">
              <input
                type="radio"
                id="yonetici"
                name="yetki"
                value="yonetici"
                v-model="isYonetici"
              />
              <label for="yonetici" class="form-label">Yönetici</label>
            </div>
            <div class="col-3">
              <input
                type="radio"
                id="calisan"
                name="yetki"
                value="calisan"
                v-model="isCalisan"
              />
              <label for="calisan" class="form-label">Çalışan</label>
            </div>
          </div>
        </div>

        <div class="mb-2">
          <label for="parola" class="form-label">Parola</label>
          <input
            type="password"
            class="form-control"
            id="parola"
            v-model="parola"
            placeholder="Parolanızı Giriniz"
          />
        </div>

        <div class="mb-2">
          <label for="parolaTekrar" class="form-label">Parola Tekrar</label>
          <input
            type="password"
            class="form-control"
            id="parolaTekrar"
            v-model="parolaTekrar"
            placeholder="Parola Tekrarınızı Giriniz"
          />
        </div>

        <div class="mb-1">
          <label for="fileSm" class="form-label">Resmini Yükleyiniz</label>
          <input
            class="form-control form-control-sm"
            id="fileSm"
            type="file"
            @change="handleChange"
          />
        </div>
        <vue-recaptcha
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          @verify="verifyMethod"
        ></vue-recaptcha>

        <button
          :disabled="buttonDisabled"
          class="w-100 btn btn-lg btn-primary"
          type="submit"
        >
          Üye Ekle
        </button>
        <hr />
        <!-- <p v-if="hataMesaj !== ''" class="text-center text-danger">
            {{ hataMesaj }}
          </p>
          <p v-if="fileHata" class="text-center text-danger">{{ fileHata }}</p> -->
      </form>
    </main>
  </div>
  <AdminFooter />
</template>
  
  <script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import useSignup from "../../composables/useSignup";
import useStorage from "../../composables/useStorage";
import useCollection from "../../composables/useCollection";
import { serverTimestamp } from "firebase/firestore";

import { deleteUser } from "firebase/auth";
import { auth } from "../../firebase/config";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { VueRecaptcha } from "vue-recaptcha";

import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    VueRecaptcha,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
  },
  setup() {
    const showModal = ref(false);

    const email = ref("");
    const kullaniciAd = ref("");
    const parola = ref("");
    const parolaTekrar = ref("");
    const isim = ref("");
    const soyisim = ref("");
    const isYonetici = ref(true); // varsayılan olarak yönetici seçili
    const isCalisan = ref(false);
    const isLoading = ref(false);

    const hataMesaj = ref("");

    const { hataSignup, signup } = useSignup();

    const file = ref(null);
    const fileHata = ref(null);

    const router = useRouter();

    const buttonDisabled = ref(true);

    const { resimYukle, url, fileYol, hataStorage } = useStorage();
    const { hataFirestore, belgeEkle } = useCollection("kullanicilar");

    const gecerliTipler = ["image/jpeg", "image/png"];

    const handleChange = (e) => {
      let secilen = e.target.files[0];
      // console.log(secilen)

      if (secilen && gecerliTipler.includes(secilen.type)) {
        file.value = secilen;
        fileHata.value = null;
      } else {
        file.value = null;
        fileHata.value = "Lütfen jpeg ya da png uzantılı dosya yükleyiniz";
        showModal = true;
      }
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      console.log("çalışan" + isCalisan.value);
      console.log("yönetici" +isYonetici.value);
      if (
        email.value !== "" &&
        kullaniciAd.value !== !"" &&
        parola.value !== "" &&
        parolaTekrar.value !== ""
      ) {
        if (parola.value == parolaTekrar.value) {
          hataMesaj.value = "";

          const res = await signup(
            email.value,
            parola.value,
            kullaniciAd.value
          );

          if (!hataSignup.value) {
            // console.log(res.user.uid);
            await resimYukle(file.value, res.user.uid);

            if (!hataStorage.value) {
              //console.log("Resim yüklendi",url.value)
              await belgeEkle(
                {
                  email: email.value,
                  kullaniciAd: kullaniciAd.value,
                  profilResim: url.value,
                  olusturulmaTarihi: serverTimestamp(),
                  durum: true,
                  isim: isim.value,
                  soyisim: soyisim.value,
                  adres: "",
                  telefonNo: "",
                },
                res.user.uid
              );

              if (!hataFirestore.value) {
                //console.log("kullanıcı oluşturuldu");
                router.push("/admin/dashboard");
              } else {
                hataMesaj.value = hataFirestore.value;
                await deleteUser(auth.currentUser);
                isLoading.value = false;
                showModal.value = true;
              }
            } else {
              hataMesaj.value = hataStorage.value;
              isLoading.value = false;
              showModal.value = true;
            }
          } else {
            hataMesaj.value = hataSignup.value;
            isLoading.value = false;
            showModal.value = true;
          }
        } else {
          hataMesaj.value = "Parola ve Parola tekrar alanları uyuşmuyor";
          isLoading.value = false;
          showModal.value = true;
        }
      } else {
        hataMesaj.value = "Alanlar boş geçilemez";
        isLoading.value = false;
        showModal.value = true;
      }
    };

    const verifyMethod = () => {
      buttonDisabled.value = false;
    };

    return {
      email,
      kullaniciAd,
      parola,
      parolaTekrar,
      hataMesaj,
      handleSubmit,
      handleChange,
      file,
      fileHata,
      isim,
      soyisim,
      buttonDisabled,
      verifyMethod,
      showModal,
      isYonetici, 
      isCalisan,
      isLoading
    };
  },
};
</script>
  
  <style scoped>
.signup-form {
  margin-top: 20px;
}
.form-signup {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>