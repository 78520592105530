<template>
  <Navbar />
  <ScrollButton />
  <section
    class="banner-title"
    style="background-image: url(images/pcb-card-banner.jpg)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
          <p>İntek Bilişim</p>
          <h3 class="news-detail-title">{{ baslik }}</h3>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>

  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-5 col-md-5 col-sm-9">
        <div class="carousel slide" id="mySlide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#mySlide"
              data-bs-slide-to="0"
              class="carousel-indicator-item active"
              aria-current="true"
              aria-label="ilk slayt"
            ></button>
            <button
              type="button"
              data-bs-target="#mySlide"
              data-bs-slide-to="1"
              class="carousel-indicator-item"
              aria-label="ikinci slayt"
            ></button>
            <button
              type="button"
              data-bs-target="#mySlide"
              data-bs-slide-to="2"
              class="carousel-indicator-item"
              aria-label="üçüncü slayt"
            ></button>
          </div>
          <div class="carousel-inner-news">
            <div class="carousel-item active">
              <img :src="resim1" class="d-block w-100" alt="slide-1" />
            </div>
            <div class="carousel-item">
              <img :src="resim2" class="d-block w-100" alt="slide-2" />
            </div>
            <div class="carousel-item">
              <img :src="resim3" class="d-block w-100" alt="slide-3" />
            </div>

            <button
              type="button"
              class="carousel-control-prev"
              data-bs-target="#mySlide"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Önceki</span>
            </button>
            <button
              type="button"
              class="carousel-control-next"
              data-bs-target="#mySlide"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Sonraki</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <h3>{{ baslik }}</h3>
        <p>{{ icerik }}</p>
      </div>
    </div>
  </div>
  <Footer />
</template>
    
    <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { useRoute } from "vue-router";
import moment from "moment";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton,
  },
  setup() {
    const route = useRoute();
    const hId = ref(route.params.newsId);
    const icerik = ref("");
    const baslik = ref("");
    const durum = ref(false);
    const resim1 = ref("");
    const resim2 = ref("");
    const resim3 = ref("");
    const olusturulmaTarihi = ref("");
    const tarih = ref("");

    moment.locale("tr");

    onMounted(async () => {
      const docRef = doc(firestore, "haberler", hId.value);
      const docSnap = await getDoc(docRef);

      const querySnapshot = await getDocs(
        collection(firestore, "haberler", hId.value, "haberDetay")
      );
      const haberDetay = [];

      querySnapshot.forEach((doc) => {
        haberDetay.push({ ...doc.data(), id: doc.id });
      });
      console.log(docSnap);
      console.log(haberDetay.length);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi;
        baslik.value = docSnap.data().baslik;
        durum.value = docSnap.data().durum;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();

        if (haberDetay.length > 0) {
          icerik.value = haberDetay[0].icerik;
          resim1.value = haberDetay[0].resim1;
          resim2.value = haberDetay[0].resim2;
          resim3.value = haberDetay[0].resim3;
        } else {
          console.log("Veriye erişilemedi");
        }
      } else {
        console.log("Veriye erişilemedi");
      }
    });
    return {
      hId,
      baslik,
      icerik,
      resim1,
      resim2,
      resim3,
      durum,
      olusturulmaTarihi,
      tarih,
    };
  },
};
</script>
    
    <style scoped>
@media (max-width: 767px) {
  .col-sm-12 {
    margin-top: 30px;
  }
}
.news-detail-title{
    color: #000;
    font-size: 30px;
    font-weight: 700px;
}
</style>