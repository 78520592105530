<template>
  <div class="uk-position-bottom">
    <div class="slidebar">
      <div class="uk-container">
        <div class="content-size">
        <ul class="uk-grid">
          <li class="flex-li">
            <router-link
              to="/automation-system"
              class="custom-link"
              @mouseenter="startAnimation" @mouseleave="stopAnimation"
            >
             <img src="images/gif/oto.png" alt="Otomasyon Pano" class="placeholder-img">
              <p class="desciption">Otomasyon Panoları</p>
            </router-link>
          </li>
          <li class="flex-li">
            <router-link
              to="/command-control-cards"
              class="custom-link"
              @mouseenter="startAnimation2" @mouseleave="stopAnimation2"
            >
             <img src="images/gif/kart.png" alt="Kontrol kartları" class="placeholder-img">
              <p class="desciption">Kontrol Kartları</p>
            </router-link>
          </li>
          <li class="flex-li">
            
            <router-link
              to="/sensor-production"
              class="custom-link"
              @mouseenter="startAnimation3" @mouseleave="stopAnimation3"
            >
             <img src="images/gif/isi-sensor.png" alt="Isı Sensörleri" class="placeholder-img">
              <p class="desciption">Isı Sensörleri</p>
            </router-link>
          </li>
          <li class="flex-li">
            <router-link
              to="/cable-bundling"
              class="custom-link"
              @mouseenter="startAnimation4" @mouseleave="stopAnimation4"
            >
             <img src="images/gif/kablo.png" alt="Kablo Gruplama" class="placeholder-img">
              <p class="desciption">Kablo Grupları
              </p>
            </router-link>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    startAnimation(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/oto.gif";
    },
    stopAnimation(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/oto.png";
    },
    startAnimation2(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/kart.gif";
    },
    stopAnimation2(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/kart.png";
    },
    startAnimation3(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/isi-sensor.gif";
    },
    stopAnimation3(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/isi-sensor.png";
    },
    startAnimation4(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/kablo.gif";
    },
    stopAnimation4(event) {
      event.currentTarget.querySelector('.placeholder-img').src = "images/gif/kablo.png";
    }
  }
};
</script>

<style scoped>


</style>