<template>
    <AdminNavbar/>
    <loading v-model:active="isLoading" :is-full-page="true" loader="bars" color="#ffc300"></loading>
    <div class="container">
      <main>
        <div class="pt-5 text-center">
          <div class="alert alert-dark" role="alert">
            <h2>Profil Bilgilerinizi Güncelleyebilirsiniz.</h2>
            <p class="lead text-danger" v-if="bilgiKontrol">Bilgileriniz kontrol ediliyor. 
              Üyelik işleminiz tamamlandığında bilgi verilecektir. </p>
              <p class="lead"><router-link to="/admin/change-auth">Kimlik bilgilerinizi güncellemek için tıklayınız</router-link></p>
          </div>
        </div>
  
        <div class="row g-5">
          <div class="col-md-8 col-lg-8">
            <h4 class="mb-3">Profil Bilgilerim</h4>
            <div class="d-flex justify-content-between">
              <p>Üyelik tarihi: {{ tarih }}</p>
              <button @click="handleBilgilerIndir"><i class="bi bi-arrow-bar-down"></i>Profil bilgilerini indir</button>
            </div>
            <form class="needs-validation" @submit.prevent="handleSubmit">
              <div class="row g-3">
                <div class="col-sm-6">
                  <label for="isim" class="form-label">İsim</label>
                  <input
                    type="text"
                    class="form-control"
                    id="isim"
                    placeholder=""
                    v-model="isim"
                  />
                </div>
  
                <div class="col-sm-6">
                  <label for="soyisim" class="form-label">Soyisim</label>
                  <input
                    type="text"
                    class="form-control"
                    id="soyisim"
                    placeholder=""
                    v-model="soyisim"
                  />
                </div>
  
                <div class="col-6">
                  <label for="adres" class="form-label">Adres</label>
                  <input
                    type="text"
                    class="form-control"
                    id="adres"
                    placeholder="Adresiniz"
                    v-model="adres"
                  />
                </div>
  
                <div class="col-6">
                  <label for="telefon" class="form-label">Telefon No</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefon"
                    placeholder="+90"
                    v-model="telefon"
                  />
                </div>
              </div>
  
              <hr class="my-4" />
  
              <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
                Güncelle
              </button>
            </form>
          </div>
  
          <div class="col-md-4 col-lg-4 order-md-last">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span>Kullanıcı Resminiz</span>
            </h4>
            <img :src="resimUrl" class="rounded img-thumbnail" ref="resim"/>
          </div>
        </div>
      </main>
    </div>
    <AdminFooter/>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import getuser from "../../composables/getUser";
  import { auth, firestore } from "@/firebase/config";
  import { doc, getDoc,updateDoc } from "firebase/firestore";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment';
  import {notify} from '@kyvg/vue3-notification'
  import AdminFooter from '@/components/AdminFooter.vue'
  import AdminNavbar from "@/components/AdminNavbar.vue";
  
  import {jsPDF} from 'jspdf'
  
  export default {
    components:{
      Loading,
      AdminFooter,
      AdminNavbar
    },
    setup() {
      const { kullanici } = getuser();
      const isLoading=ref(true);
  
      const tarih = ref("");
      const isim = ref("");
      const soyisim = ref("");
      const adres = ref("");
      const telefon = ref("");
      const resimUrl = ref("");
  
      const resim=ref(null)
  
      const bilgiKontrol = ref(false);
      moment.locale('tr')
  
      onMounted(async () => {
  
        const docRef = doc(firestore, "kullanicilar", kullanici.value.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
  
          tarih.value = docSnap.data().olusturulmaTarihi.toDate();
          isim.value = docSnap.data().isim;
          soyisim.value = docSnap.data().soyisim;
          telefon.value = docSnap.data().telefonNo;
          adres.value = docSnap.data().adres;
          resimUrl.value = docSnap.data().profilResim;
  
          tarih.value=moment(tarih.value).fromNow();
  
          if (docSnap.data().yetki == "basit") {
            bilgiKontrol.value = true;
          }
          isLoading.value=false;
        } else {
          console.log("Veriye erişilemedi");
        }
      })
  
      const handleSubmit =async()=>{
        isLoading.value=true;
  
        const docRef = doc(firestore, "kullanicilar", kullanici.value.uid);
        updateDoc(docRef,{
          isim:isim.value,
          soyisim:soyisim.value,
          adres:adres.value,
          telefonNo:telefon.value
        }).then(()=>{
  
          isLoading.value=false;
          notify({
            title:'Güncellendi',
            text:'Kullanıcı bilgileri güncellendi',
            type:'success',
            duration:2000
  
          })
        }).catch(()=>{
          isLoading.value=false
          notify({
            title:'Uppppsss Hata Oluştu',
            text:'Kullanıcı bilgileri güncellenirken hata oluştu',
            type:'error',
            duration:2000
  
          })
        })
  
      }
  
      const handleBilgilerIndir=async()=>{
        const pdfDoc=new jsPDF('landscape');
        const docRef=doc(firestore,'kullanicilar',kullanici.value.uid)
  
        const docSnap=await getDoc(docRef);
  
        if(docSnap.exists()){
          pdfDoc.setFontSize(17);
          pdfDoc.text('İntek Bilişim Profil Bilgileriniz',95,10);
          var img=new Image;
          img.src=resim.value.src;
          pdfDoc.addImage(img,'JPEG',25,15,50,50);
          pdfDoc.setFontSize(12);
  
          pdfDoc.text('Kullanici Ad:',120,25);
          pdfDoc.text(docSnap.data().kullaniciAd,170,25);
  
          pdfDoc.text('Isim Soyisim:',120,35);
          pdfDoc.text(docSnap.data().isim+ " " + docSnap.data().soyisim,170,35);
  
          pdfDoc.text('Email:',120,45);
          pdfDoc.text(docSnap.data().email,170,45);
  
          pdfDoc.text('Telefon:',120,55);
          pdfDoc.text(docSnap.data().telefonNo,170,55);
  
          pdfDoc.text('Adres:',30,85);
          pdfDoc.text(docSnap.data().adres,50,85);
  
          pdfDoc.save('intek-bilisim.pdf')
        }
      }
  
      return {tarih,isim,soyisim,adres,telefon,resimUrl,bilgiKontrol,isLoading,handleSubmit,handleBilgilerIndir,resim}
    }
  };
  </script>
  
  <style>
  </style>
  
  
  