<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/pcb-device.jpg)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Haberler</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Haberler</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section id="ts-team" class="ts-team" style="background-color: #f8f8f8">
    <div class="container">
      <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">İntek Bilişim'den Haberler</div>
        <div class="line"></div>
      </div>
      <!--/ Title row end -->

      <div class="row" v-if="displayedNews.length !== 0">
        <div
          class="col-lg-4 col-md-4 col-sm-6"
          v-for="news in displayedNews"
          :key="news"
        >
          <div id="team-slide" class="team-slide">
            <div class="item">
              <ul>
                <li>
                  <h5><i class="fas fa-calendar-alt calendar">{{
                   news.olusturulmaTarihi
                  }}</i></h5>
                </li>
              </ul>
              <div class="ts-team-wrapper">
                <div class="team-img-wrapper">
                  <img
                    loading="lazy"
                    style="height: 300px;"
                    class="w-100 mb-2"
                    :src="news.resim"
                    alt="team-img"
                  />
                </div>
                <router-link
                  :to="{
                    name: 'NewsDetailById',
                    params: { newsId: news.id },
                  }"
                >
                  <div class="ts-team-content">
                    <h3 class="ts-name">{{ news.baslik }}</h3>
                    <p class="ts-designation">{{ news.altbaslik }}</p>
                    <p class="ts-description truncate">{{ news.icerik }}</p>
                  </div>
                </router-link>
              </div>
              <!--/ Team wrapper end -->
            </div>
            <!-- Team 2 end -->
          </div>
          <!-- Team slide end -->
        </div>
      </div>
      <!--/ Content row end -->
      <div class="pagination">
        <button @click="prevPage" v-if="currentPage > 1">
          <i class="bi bi-chevron-double-left"></i>
        </button>
        <button
          v-for="pageNumber in totalPages"
          :key="pageNumber"
          @click="goToPage(pageNumber)"
          :class="{ active: currentPage === pageNumber }"
        >
          {{ pageNumber }}
        </button>
        <button @click="nextPage" v-if="currentPage < totalPages">
          <i class="bi bi-chevron-double-right"></i>
        </button>
      </div>
    </div>
    <!--/ Container end -->
  </section>
  <Footer/>
</template>
  
  <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref, onMounted,computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import { formatDistance } from 'date-fns';
import moment from "moment";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer
  },
  data() {
    return {
      slickOptions: {
        arows: false,
        slidesToShow: 3,
      },
    };
  },
  setup() {
    const newsList = ref([]);
    const isLoading = ref(true);
    const currentPage = ref(1);
    const pageSize = 3; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    moment.locale("tr");
    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedNews = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(newsList.value.slice(startIndex, endIndex));
      return newsList.value.slice(startIndex, endIndex);
    });
    // Sayfa değiştirme işlevi
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };

    const formatDate=(date)=>{
      return formatDistance(date, new Date(), { addSuffix: true });
    }
    onMounted(async () => {
      let q = query(
        collection(firestore, "haberler"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc"),
        limit(20)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      newsList.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;

      newsList.value.forEach((item) => {
      item.olusturulmaTarihi= moment(item.olusturulmaTarihi.toDate()).format("DD-MM-YYYY");
      });
    });
    return {
      newsList,
      isLoading,
      currentPage,
      totalPages,
      goToPage,
      displayedNews,
      prevPage,
      nextPage,
      formatDate
    };
  },
};
</script>
  
<style scoped>
ul {
  list-style: none;
}
.carousel-wrapper {
  padding: 40px;
}
.img-wrapper img {
  margin: auto;
  width: 200px;
  height: 100px;
  background-image: linear-gradient(gray 100%, transparent 0);
}
.section-sub-title {
  border-bottom: 1px solid rgba(157, 157, 157, 0.5);
}
.calendar{
  color:#145da0;
  font-style: normal;
}
</style>