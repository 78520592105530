<template>
  <Navbar />
  <ScrollButton />
  <section class="banner-title" style="background-image: url(images/auto.png)">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Otomasyon Sistemleri</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Otomasyon Sistemleri</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-section " id="portfolio">
    <div class="container">
      <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">Otomasyon Sistemlerimiz</div>
        <div class="line"></div>
      </div>
      <div class="row">
        <div class="text-center">
          <p>
            Firmamız, endüstriyel otomasyon alanında, müşterilerimizin benzersiz
            ihtiyaçlarına uygun çözümler sunarak öne çıkıyor. Seri üretimde
            uzmanlaşmış bir ekip olarak, müşterilerimize özel otomasyon
            sistemleri sunuyor ve işletmelerinin verimliliğini artırmalarına
            yardımcı oluyoruz.
          </p>
          <p><b>Esneklik ve Özelleştirme</b></p>
          <p>
            Her müşterinin işletmesi farklıdır ve farklı ihtiyaçlara sahiptir.
            Bu nedenle, otomasyon sistemlerimizi esnek bir şekilde
            özelleştirebiliyoruz. Müşterilerimizin taleplerine göre tasarlanan
            ve üretilen sistemlerimiz, işletmelerin özel gereksinimlerini
            karşılamak için idealdir.
          </p>

          <p><b>Yüksek Kalite ve Güvenilirlik</b></p>
          <p>
            Seri üretimde kalite ve güvenilirlik önemlidir. Firmamız, endüstri
            standartlarına uygun olarak yüksek kaliteye sahip bileşenler
            kullanarak ve titiz kalite kontrol süreçleriyle üretim yapmaktadır.
            Bu sayede, müşterilerimize güvenilir ve dayanıklı otomasyon
            sistemleri sunuyoruz.
          </p>

          <p><b>Hızlı Teslimat ve Destek</b></p>
          <p>
            Müşterilerimizin zamanında teslimat alması bizim için önemlidir.
            Seri üretim süreçlerimiz, hızlı ve verimli bir şekilde
            gerçekleştirilir, böylece müşterilerimiz işletmelerindeki
            operasyonları aksatmadan devam ettirebilirler. Ayrıca, satış sonrası
            destek ekibimiz her zaman müşterilerimizin yanındadır ve herhangi
            bir sorunla karşılaşmaları durumunda hızlı çözümler sunar.
          </p>

          <p><b>Sizin İçin Buradayız</b></p>
          <p>
            Firmamız, müşteri memnuniyetini ön planda tutar ve işletmelerin
            başarısına katkıda bulunmak için çaba sarf eder. Siz de işletmenizin
            otomasyon ihtiyaçları için bize güvenebilirsiniz. Hemen bizimle
            iletişime geçin ve özel ihtiyaçlarınıza uygun otomasyon çözümleri
            hakkında daha fazla bilgi alın.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="text-uppercase line-wrapper mt-5">
          <div class="text">Galeri</div>
          <div class="line"></div>
        </div>
        <div
          class="col-lg-3 col-sm-6 mb-3"
          v-for="(item, index) in automations"
          :key="index"
        >
          <!-- Portfolio item 1-->
          <div class="portfolio-item">
            <router-link
              :to="'/' + item.to"
              class="portfolio-link"
            >
              <img
                class="img-fluid"
                :src="item.logo"
                :alt="item.baslik"
                style="width: 300px; height: 300px"
              />

              <div class="portfolio-caption">
                <div class="portfolio-caption-heading">
                  {{ item.baslik }}
                </div>
                <div class="portfolio-caption-subheading text-muted">
                  {{ item.icerik }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { query, collection, getDocs, where } from "firebase/firestore";

import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
    FontAwesomeIcon,
  },
  setup() {
    const automations = ref([]);

    onMounted(async () => {
      let q = query(
        collection(firestore, "otomasyon"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      automations.value = veri;
    });

    return {
      automations,
    };
  },
};
</script>
  
  <style scoped>
</style>