<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/uretim.png)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Biz Kimiz</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Biz Kimiz</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-container">
    <div class="auto-container">
      <div class="row">
        <div
          class="content-side pull-left col-lg-12 col-md-8 col-sm-12 col-xs-12"
        >
          <div class="blog-section">
            <div class="news-block-detail">
              <div class="title-content">
                <h3>Biz Kimiz</h3>
              </div>
              <div class="text-content">
                <div class="right-img" >
                  <img class="about-img"
                    src="images/about.jpeg"
                  />
                </div>
                <div class="left-text" >
                  <blockquote>
                    <p>
                      Bir aile şirketi olarak 1996 yılında faaliyetlerine
                      başlayan firmamız 2004 yılından beri intek teknoloji
                      olarak elektronik ve otomasyon guruplarında üretim taahhüt
                      faliyetlerini sürdürmektedir. </p>
                      <p class="mt-2">Sektör olarak otomasyon,
                    elektronik ve kumanda ekipmanlarında en yeni teknolojileri
                      kullanarak projelerini başarıyla tamamlamış olan firmamız
                      yeni ürün yelpazesi ve farklı üretim seçenekleriyle
                      kaliteli hızlı üretim ve montaj politikasını esnek hizmet
                      anlayışıyla birleştirmiştir. </p>
                      <p class="mt-2">İntek teknoloji 2007 yılında
                      hizmetinin ana girdisini oluşturan elektronik ve
                      otomasyonu elektromekanikle birleştirerek bütün projeleri
                      kendi bünyesinde yaparak daha güçlü projeler üretmeyi
                      başarmıştır. Sahip oldugumuz prensipler çerçevesinde
                      oluşturduğumuz sistemli çalışmalarımız sonucunda dünyaca
                      ünlü markalarla çalışma fırsatı yakalamıştır.</p>
                      <p class="mt-2"> Kurumsal
                      değerlerimizi,evrensel yönetim ilkeleriyle
                      birleştirip,teknolojik gelişmeleri makina parkurumuzda
                      uygulayarak kaliteli ürünleri makul maliyetlerle
                      üretebilmekteyiz
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</template>

<script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer
  },
};
</script>

<style>
</style>