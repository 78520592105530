<template>
  <AdminNavbar />
  <div class="container px-4 py-5" id="hanging-icons">
    <h1 class="pb-2 border-bottom">Dashboard</h1>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="col d-flex align-items-start">
        <div>
          <h3>Yeni Kullanıcı Listesi</h3>
          <p>
            Yeni üye olmuş kullanıcıları görüntüleyebilir ve
            yetkilendirebilirsiniz.
          </p>
          <router-link
            to="/admin/new-user-list"
            class="btn btn-outline-primary btn-sm"
          >
            Listeye Git
          </router-link>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div>
          <h3>Kullanıcı Durum Değiştir</h3>
          <p>
            Kullanıcıların durumları görüntüleyebilir ve değiştirebilirsiniz.
          </p>
          <router-link
            to="/admin/change-user-status"
            class="btn btn-outline-primary btn-sm"
          >
            Kullanıcı Durum Değiştir
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <AdminFooter />
</template>
  
  <script>
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
export default {
  components: {
    AdminFooter,
    AdminNavbar,
  },
};
</script>
  
  <style>
</style>