import { createRouter, createWebHashHistory } from 'vue-router'

import { auth, firestore } from '@/firebase/config'
import { doc, getDoc } from 'firebase/firestore'


/* Admin */
import ChangeAuth from '../views/admin/ChangeAuth'
// import Signup from '../views/admin/Signup'
import Login from '../views/admin/Login'
import Profile from '../views/admin/Profile'
import Dashboard from '../views/admin/Dashboard.vue'
import AddMember from '../views/admin/AddMember.vue'

//kablo grup
import CableGroupList from '../views/admin/cable-group/CableGroupList.vue'
import CableGroupDetail from '../views/admin/cable-group/CableGroupDetail.vue'
import AddCableGroup from '../views/admin/cable-group/AddCableGroup.vue'

//sensor
import SensorList from '../views/admin/sensor/SensorList.vue'
import SensorDetail from '../views/admin/sensor/SensorDetail.vue'
import AddSensor from '../views/admin/sensor/AddSensor.vue'

//kumanda kontrol
import CommandControlList from '../views/admin/command-control/CommandControlList.vue'
import CommandControlDetail from '../views/admin/command-control/CommandControlDetail.vue'
import AddCommandControl from '../views/admin/command-control/AddCommandControl.vue'

//prototip
import PrototypeList from '../views/admin/prototype/PrototypeList.vue'
import PrototypeDetail from '../views/admin/prototype/PrototypeDetail.vue'
import AddPrototype from '../views/admin/prototype/AddPrototype.vue'

//otomasyon
import AutomationList from '../views/admin/automation/AutomationList.vue'
import AutomationDetail from '../views/admin/automation/AutomationDetail.vue'
import AddAutomation from '../views/admin/automation/AddAutomation.vue'

//arge
import ArgeList from '../views/admin/arge/ArgeList.vue'
import ArgeDetail from '../views/admin/arge/ArgeDetail.vue'
import AddArge from '../views/admin/arge/AddArge.vue'

//haberler - news
import NewsList from '../views/admin/news/NewsList.vue'
import NewsDetail from '../views/admin/news/NewsDetail.vue'
import AddNews from '../views/admin/news/AddNews.vue'

import NewsDetailById from '../views/frontend/NewsDetailById'

//sertifikalar
import CertificateList from '../views/admin/certificates/CertificateList.vue'
import CertificateDetail from '../views/admin/certificates/CertificateDetail.vue'
import AddCertificate from '../views/admin/certificates/AddCertificate.vue'

//referanslar
import ReferenceList from '../views/admin/references/ReferenceList.vue'
import ReferenceDetail from '../views/admin/references/ReferenceDetail.vue'
import AddReference from '../views/admin/references/AddReference.vue'

//Pergola
import PergolaList from '../views/admin/pergola/PergolaList.vue'
import PergolaDetail from '../views/admin/pergola/PergolaDetail.vue'
import AddPergola from '../views/admin/pergola/AddPergola.vue'

//tea
import TeaDetail from '../views/admin/tea/TeaDetail.vue'
import TeaList from '../views/admin/tea/TeaList.vue'
import AddTea from '../views/admin/tea/AddTea.vue'

//garbage
import GarbageDetail from '../views/admin/garbage/GarbageDetail.vue'
import GarbageList from '../views/admin/garbage/GarbageList.vue'
import AddGarbage from '../views/admin/garbage/AddGarbage.vue'

//automation panel
import AutomationPanelDetail from '../views/admin/automation-panel/AutomationPanelDetail.vue'
import AutomationPanelList from '../views/admin/automation-panel/AutomationPanelList.vue'
import AddAutomationPanel from '../views/admin/automation-panel/AddAutomationPanel.vue'

import NewUserList from '../views/admin/dashboard/NewUserList.vue'
import UserDetail from '../views/admin/dashboard/UserDetail.vue'
import ChangeUserStatus from '../views/admin/dashboard/ChangeUserStatus.vue'


/* Frontend */
import HomeView from '../views/HomeView.vue'
import Contact from '../views/frontend/Contact.vue'
import Certificates from '../views/frontend/Certificates.vue'
import QualityPolicy from '../views/frontend/QualityPolicy.vue'
import News from '../views/frontend/News.vue'
import About from '../views/frontend/About.vue'
import WhatWeDo from '../views/frontend/WhatWeDo.vue'
import Values from '../views/frontend/Values.vue'
import Services from '../views/frontend/Services.vue'
import AutomationSystem from '../views/frontend/AutomationSystem.vue'
import CableBundling from '../views/frontend/CableBundling.vue'
import CommandControlCards from '../views/frontend/CommandControlCards.vue'
import PrototypeProduction from '../views/frontend/PrototypeProduction.vue'
import SensorProduction from '../views/frontend/SensorProduction.vue'
import Arge from '../views/frontend/Arge.vue'
import Garbage from '../views/frontend/Garbage.vue'
import Tea from '../views/frontend/Tea.vue'
import Pergola from '../views/frontend/Pergola.vue'
import AutomationPanel from '../views/frontend/AutomationPanel.vue'


const adminKontrol = async (to, from, next) => {

  let kullanici = auth.currentUser;

  if (!kullanici) {
    next({ name: 'Login' })
  }
  else {
    const docRef = doc(firestore, 'kullanicilar', kullanici.uid)

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      if (docSnap.data().yetki == 'yonetici') {
        next()
      }
      else {
        next({ name: 'Profile' })
      }
    }
    else {
      next({ name: 'Login' })
    }
  }

}
const basitKontrol = async (to, from, next) => {

  let kullanici = auth.currentUser;

  if (!kullanici) {
    next({ name: 'Login' })
  }
  else {
    const docRef = doc(firestore, 'kullanicilar', kullanici.uid)

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      if (docSnap.data().yetki == 'calisan' || docSnap.data().yetki == 'yonetici') {
        next()
      }
      else {
        next({ name: 'Profile' })
      }
    }
    else {
      next({ name: 'Login' })
    }
  }

}

const routes = [
  /*Frontend */
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/certificates',
    name: 'Certificates',
    component: Certificates
  },
  {
    path: '/quality-policy',
    name: 'QualityPolicy',
    component: QualityPolicy
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/what-we-do',
    name: 'WhatWeDo',
    component: WhatWeDo
  },
  {
    path: '/values',
    name: 'Values',
    component: Values
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/automation-system',
    name: 'AutomationSystem',
    component: AutomationSystem
  },
  {
    path: '/cable-bundling',
    name: 'CableBundling',
    component: CableBundling
  },
  {
    path: '/prototype-production',
    name: 'PrototypeProduction',
    component: PrototypeProduction
  },
  {
    path: '/sensor-production',
    name: 'SensorProduction',
    component: SensorProduction
  },
  {
    path: '/command-control-cards',
    name: 'CommandControlCards',
    component: CommandControlCards
  },
  {
    path: '/arge',
    name: 'Arge',
    component: Arge
  },
  {
    path: '/garbage',
    name: 'Garbage',
    component: Garbage
  },
  {
    path: '/tea',
    name: 'Tea',
    component: Tea
  },
  {
    path: '/pergola',
    name: 'Pergola',
    component: Pergola
  },
  {
    path: '/automation-panel',
    name: 'AutomationPanel',
    component: AutomationPanel
  },
      /*Admin */
  {
    path: '/admin/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/admin/addMember',
    name: 'AddMember',
    component: AddMember
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin/change-auth',
    name: 'ChangeAuth',
    component: ChangeAuth
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/admin',
    name: 'admin',
    component: Dashboard
  },
  {
    path: '/admin/automation-list',
    name: 'AutomationList',
    component: AutomationList
  },
  {
    path: '/admin/automation-detail/:autoId',
    name: 'AutomationDetail',
    component: AutomationDetail
  },
  {
    path: '/admin/add-automation',
    name: 'AddAutomation',
    component: AddAutomation
  },
  {
    path: '/admin/command-control-list',
    name: 'CommandControlList',
    component: CommandControlList
  },
  {
    path: '/admin/command-control-detail/:ccId',
    name: 'CommandControlDetail',
    component: CommandControlDetail
  },
  {
    path: '/admin/add-command-control',
    name: 'AddCommandControl',
    component: AddCommandControl
  },
  {
    path: '/admin/cable-group-list',
    name: 'CableGroupList',
    component: CableGroupList
  },
  {
    path: '/admin/cable-group-detail/:cableId',
    name: 'CableGroupDetail',
    component: CableGroupDetail
  }, {
    path: '/admin/add-cable-group',
    name: 'AddCableGroup',
    component: AddCableGroup
  },
  {
    path: '/admin/sensor-list',
    name: 'SensorList',
    component: SensorList
  },
  {
    path: '/admin/sensor-detail/:sensorId',
    name: 'SensorDetail',
    component: SensorDetail
  },
  {
    path: '/admin/add-sensor',
    name: 'AddSensor',
    component: AddSensor
  },
  {
    path: '/admin/prototype-list',
    name: 'PrototypeList',
    component: PrototypeList
  },
  {
    path: '/admin/prototype-detail/:proId',
    name: 'PrototypeDetail',
    component: PrototypeDetail
  },
  {
    path: '/admin/add-prototype',
    name: 'AddPrototype',
    component: AddPrototype
  },
  {
    path: '/admin/arge-list',
    name: 'ArgeList',
    component: ArgeList
  },
  {
    path: '/admin/arge-detail/:argeId',
    name: 'ArgeDetail',
    component: ArgeDetail
  },
  {
    path: '/admin/add-arge',
    name: 'AddArge',
    component: AddArge
  },
  {
    path: '/admin/change-user-status',
    name: 'ChangeUserStatus',
    component: ChangeUserStatus
  },
  {
    path: '/admin/new-user-list',
    name: 'NewUserList',
    component: NewUserList
  },
  {
    path: '/admin/user-detail/:userId',
    name: 'UserDetail',
    component: UserDetail
  },
  {
    path: '/admin/certificate-list',
    name: 'CertificateList',
    component: CertificateList
  },
  {
    path: '/admin/certificate-detail/:cerId',
    name: 'CertificateDetail',
    component: CertificateDetail
  },
  {
    path: '/admin/add-certificate',
    name: 'AddCertificate',
    component: AddCertificate
  },
  {
    path: '/admin/news-list',
    name: 'NewsList',
    component: NewsList
  },
  {
    path: '/admin/news-detail/:newsId',
    name: 'NewsDetail',
    component: NewsDetail
  },
  {
    path: '/admin/add-news',
    name: 'AddNews',
    component: AddNews
  },

  {
    path: '/news-detail-by-id/:newsId',
    name: 'NewsDetailById',
    component: NewsDetailById
  },
  {
    path: '/admin/reference-list',
    name: 'ReferenceList',
    component: ReferenceList
  },
  {
    path: '/admin/reference-detail/:refId',
    name: 'ReferenceDetail',
    component: ReferenceDetail
  },
  {
    path: '/admin/add-reference',
    name: 'AddReference',
    component: AddReference
  },

  {
    path: '/admin/garbage-list',
    name: 'GarbageList',
    component: GarbageList
  },
  {
    path: '/admin/garbage-detail/:garbageId',
    name: 'GarbageDetail',
    component: GarbageDetail
  },
  {
    path: '/admin/add-garbage',
    name: 'AddGarbage',
    component: AddGarbage
  },

  {
    path: '/admin/tea-list',
    name: 'TeaList',
    component: TeaList
  },
  {
    path: '/admin/tea-detail/:teaId',
    name: 'TeaDetail',
    component: TeaDetail
  },
  {
    path: '/admin/add-tea',
    name: 'AddTea',
    component: AddTea
  },

  {
    path: '/admin/pergola-list',
    name: 'PergolaList',
    component: PergolaList
  },
  {
    path: '/admin/pergola-detail/:pergolaId',
    name: 'PergolaDetail',
    component: PergolaDetail
  },
  {
    path: '/admin/add-pergola',
    name: 'AddPergola',
    component: AddPergola
  },

  {
    path: '/admin/automation-panel-list',
    name: 'AutomationPanelList',
    component: AutomationPanelList
  },
  {
    path: '/admin/automation-panel-detail/:panelId',
    name: 'AutomationPanelDetail',
    component: AutomationPanelDetail
  },
  {
    path: '/admin/add-automation-panel',
    name: 'AddAutomationPanel',
    component: AddAutomationPanel
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
