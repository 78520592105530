<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/banner-about.jpeg)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Sertifikalarımız</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Sertifikalarımız</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <!-- Banner area end -->
</template>
  
  <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
export default {
  components: {
    Navbar,
    ScrollButton,
  },
};
</script>
  
  <style>
</style>