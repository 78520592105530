<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/banner-cards.jpg)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h2>Kumanda Ve Kontrol Kartları</h2>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Kumanda Ve Kontrol Kartları</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <section class="page-section" id="portfolio">
    <div class="container">
      <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">Kumanda Ve Kontrol Kartlarımız</div>
        <div class="line"></div>
      </div>
      <div class="row">
      <div class="text-center">
        <p><b>Bilişim Vadisi'nde Elektronik Sistemler ve PCB Tasarımı: İnovasyonun Kalbinde</b></p>
        <p>
          İntek olarak Bilişim Vadisi'nde, elektronik sistemlerin ve PCB tasarımının öncüsüdür. İleri teknolojiye sahip tesislerimizde,
           müşterilerimize yüksek kaliteli elektronik çözümler sunuyoruz.
        </p>
        <p><b>Elektronik Sistemlerde Uzmanlık</b></p>
        <p>
          Gelişmiş elektronik sistemlerin tasarımı ve üretimi konusunda uzman ekibimiz, 
          müşterilerimizin benzersiz gereksinimlerini karşılamak için çözümler üretiyor.
           Endüstri standartlarını aşan kalite ve performans ile müşterilerimize rekabet avantajı sağlıyoruz.
        </p>

        <p><b>PCB Tasarımında İnovasyon</b></p>
        <p>
          PCB (Printed Circuit Board) tasarımında uzmanız. Müşterilerimizin taleplerine uygun olarak özelleştirilmiş
           PCB tasarımları sunuyoruz. Yüksek hassasiyet, 
          düşük maliyet ve hızlı üretim süreçleriyle, müşterilerimizin projelerini hızla hayata geçirmelerine yardımcı oluyoruz.
        </p>

        <p><b>Embedded Yazılım Geliştirme</b></p>
        <p>
          Gelişmiş gömülü sistemlerin yazılım geliştirme sürecinde uzman ekibimiz, müşterilerimize özel yazılım çözümleri sunuyor. 
          Gömülü yazılımın karmaşıklığını anlayarak, en uygun ve güvenilir çözümleri sağlıyoruz.
        </p>

        <p><b>İnovasyon ve İşbirliği</b></p>
        <p>
          Firmamız, sürekli olarak yenilikçi çözümler geliştirme ve işbirliği yapma konusunda kararlıdır.
           Bilişim Vadisi'nin dinamik ortamında,
          teknoloji ve iş dünyasının geleceğine katkıda bulunmak için çalışıyoruz.
        </p>
        
        <p><b>Sizin Projeleriniz İçin Buradayız</b></p>
        <p>
          Firmamız, müşteri odaklı yaklaşımıyla, projelerinizin başarılı bir şekilde gerçekleştirilmesini sağlamak için burada. 
          İhtiyaçlarınızı karşılamak ve geleceğin teknolojilerini birlikte şekillendirmek için bize bugün ulaşın.
        </p>
      </div>
      </div>
      <div class="row">
        <div class="text-uppercase line-wrapper mt-5">
          <div class="text">Galeri</div>
          <div class="line"></div>
        </div>
        <div
            class="col-lg-3 col-sm-6 mb-3"
            v-for="(item, index) in cards"
            :key="index"
          >
            <!-- Portfolio item 1-->
            <div class="portfolio-item">
              <a
                class="portfolio-link"
                data-bs-toggle="modal"
                :href="'#portfolioModal' + index"
              >
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="plus" />
                  </div>
                </div>
                <img class="img-fluid" :src="item.logo" :alt="item.baslik" />
              </a>
            </div>
          </div>
      </div>
    </div>
  </section>
  <Footer />
  <div
      class="portfolio-modal modal fade"
      v-for="(item, index) in cards"
      :key="index"
      :id="'portfolioModal' + index"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="close-modal" data-bs-dismiss="modal">
            <img src="images/close-icon.svg" alt="Close modal" />
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="modal-body">
                  <!-- Project details-->
                  <img
                    class="img-fluid d-block mx-auto"
                    :src="item.logo"
                    alt="Otomasyon Sistemleri"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import { ref, onMounted } from "vue";
  import { firestore } from "@/firebase/config";
  import { query, collection, getDocs, where } from "firebase/firestore";
  
  import Navbar from "@/components/Navbar-2.vue";
  import ScrollButton from "@/components/ScrollButton.vue";
  import Footer from "@/components/Footer.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  
  export default {
    components: {
      Navbar,
      ScrollButton,
      Footer,
      FontAwesomeIcon,
    },
    setup() {
      const cards = ref([]);
  
      onMounted(async () => {
        let q = query(
          collection(firestore, "kumandakontrol"),
          where("durum", "==", true)
        );
  
        const querySnapshot = await getDocs(q);
  
        const veri = [];
  
        querySnapshot.forEach((doc) => {
          veri.push({ ...doc.data(), id: doc.id });
        });
  
        cards.value = veri;
      });
  
      return {
        cards,
      };
    },
  };
  </script>

<style>
</style>