<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Otomasyon Bilgisi Ekle</h2>
          <p class="lead">
            <router-link to="/admin/automation-list"
              >Otomasyon Listesi için Tıklayınız</router-link
            >
          </p>
        </div>
      </div>
      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Otomasyon Bilgileri</h4>
          <div class="d-flex justify-content-between"></div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="baslik" class="form-label">Başlık</label>
                <input
                  type="text"
                  class="form-control"
                  id="baslik"
                  placeholder=""
                  v-model="baslik"
                />
              </div>

              <div class="col-sm-6">
                <label for="icerik" class="form-label">İçerik</label>
                <input
                  type="text"
                  class="form-control"
                  id="icerik"
                  placeholder=""
                  v-model="icerik"
                />
              </div>
              <div class="col-6">
                Durum
                <label for="aktif" class="form-label">Durum</label>
                <div class="col-3">
                  <input
                    type="radio"
                    id="aktif"
                    name="durum"
                    :value="true"
                    v-model="durum"
                  />
                  <label for="aktif" class="form-label">Aktif</label>
                </div>
                <div class="col-3">
                  <input
                    type="radio"
                    id="pasif"
                    name="durum"
                    :value="false"
                    v-model="durum"
                  />
                  <label for="pasif" class="form-label">Pasif</label>
                </div>
              </div>
              <div class="mb-1">
                <label for="fileSm" class="form-label"
                  >Resmini Yükleyiniz</label
                >
                <input
                  class="form-control form-control-sm"
                  id="fileSm"
                  type="file"
                  @change="handleChange"
                />
              </div>
            </div>

            <hr class="my-4" />
            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
               Ekle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
      
      <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, collection, updateDoc } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import { serverTimestamp } from "firebase/firestore";
import { useRouter } from "vue-router";
import moment from "moment";
import useStorage from "../../../composables/useStorage";
import useCollection from "../../../composables/useCollection";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    ModalsContainer,
    VueFinalModal,
  },
  setup() {
    const showModal = ref(false);
    const isLoading = ref(true);
    const olusturulmaTarihi = ref("");
    const baslik = ref("");
    const logo = ref("");
    const icerik = ref("");
    const durum = ref(true);

    const file = ref(null);
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const router = useRouter();
    moment.locale("tr");
    const { resimYukle, url, fileYol, hataStorage } = useStorage();
    const { hataFirestore, yeniBelgeEkle, id } = useCollection("otomasyon");
    const gecerliTipler = ["image/jpeg", "image/png"];

    onMounted(async () => {
      isLoading.value = false;
    });

    const handleChange = (e) => {
      let secilen = e.target.files[0];
      // console.log(secilen)

      if (secilen && gecerliTipler.includes(secilen.type)) {
        file.value = secilen;
        fileHata.value = null;
      } else {
        file.value = null;
        fileHata.value = "Lütfen jpeg ya da png uzantılı dosya yükleyiniz";
        showModal = true;
      }
    };

    const handleSubmit = async () => {
      isLoading.value = true;

      const myDocumentData = {
        baslik: baslik.value,
        icerik: icerik.value,
        logo: file.value.name,
        durum: durum.value === true ? true : false,
        olusturulmaTarihi: serverTimestamp(),
      };

      console.log(myDocumentData);
      await yeniBelgeEkle(myDocumentData);
      if (!hataFirestore.value) {
        await resimYukle(file.value, id.value);

        if (!hataStorage.value) {
          const myCollection = collection(firestore, "otomasyon");
          const myDocRef = doc(myCollection, id.value);

          try {
            const data = {
              logo: url.value,
            };
            await updateDoc(myDocRef, data);
            router.push("/admin/automation-list");
          } catch (error) {
            hataMesaj.value = error.message;
            isLoading.value = false;
            showModal.value = true;
          }

          //update resim
        } else {
          hataMesaj.value = hataStorage.value;
          isLoading.value = false;
          showModal.value = true;
        }
      } else {
        hataMesaj.value = hataFirestore.value;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      olusturulmaTarihi,
      icerik,
      baslik,
      logo,
      isLoading,
      showModal,
      fileHata,
      hataMesaj,
      file,
      handleSubmit,
      handleChange,
      durum,
    };
  },
};
</script>
      
      <style>
</style>
      
      
      