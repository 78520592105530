<template>
  <section
    class="banner-down"
    style="background-image: url(images/world-gray.png)"
  >
    <div class="banner-text">
      <h3 c>
        İntek Teknoloji olarak <br /><b>
          Araştırıyoruz, Geliştiriyoruz, Üretiyoruz</b
        >
      </h3>
    </div>
    <!-- <div class="banner-text">
        <h1>İntek Teknoloji OLARAK: Araştırıyoruz, Geliştiriyoruz, Üretiyoruz</h1>
    </div> -->
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.banner-down {
  position: relative;
  background-size: cover;
  height: 390px;
  background-repeat: no-repeat;
}
.banner-text {
  position: relative;
}

.banner-text h3 {
  position: relative;
  font-size: 32px;
  line-height: 1.5em;
  font-weight: 500;
  color: #145da0;
  font-family: "Montserrat", sans-serif;
  left: 100px;
  margin-top: 30px;
  text-transform: capitalize;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media (max-width: 768px) {
  .banner-text h3 {
    font-size: 32px;
    line-height: 1.5em;
    font-weight: 500;
    left: 100px;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .banner-down {
    height: 250px;
  }
  .banner-text h3 {
    font-size: 25px;
    line-height: 1em;
    font-weight: 400;
    left: 50px;
    margin-top: 50px;
  }
}
@media (max-width: 400px) {
  .banner-text h3 {
    font-size: 22px;
    line-height: 1em;
    font-weight: 400;
    float: left;
    margin-top: 50px;
  }
}
</style>