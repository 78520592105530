<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/ban-pcb.png)"
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>Ne Yapıyoruz</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">Ne Yapıyoruz</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>

  <section class="page-container">
    <div class="auto-container">
      <div class="row">
        <div
          class="content-side pull-left col-lg-12 col-md-8 col-sm-12 col-xs-12"
        >
          <div class="blog-section">
            <div class="news-block-detail">
              <div class="title-content">
                <h3>Ne Yapıyoruz</h3>
              </div>
              <div class="text-content">
                <div class="right-img">
                  <img
                    src="images/whatwedo.jpeg"
                    class="about-img"
                  />
                </div>
                <div class="left-text">
                  <blockquote>
                    <p>
                      Elektronik ve otomasyon sektöründe yaptığımız projelerle
                      mekaniye hareket kabiliyeti ve otonom özelliğini
                      teknolojinin tepe noktasında sağlıyoruz.
                    </p>
                    <p class="mt-2">
                      Elektronik dizgi kablo guruplama,montaj ve test
                      makinalarıyla yüksek üretim potansiyelimizi kalite odaklı
                      strateji ile birleştirip kaliteli ürün üretmeyi ön planda
                      tutuyoruz.
                    </p>
                    <p class="mt-4">
                      <b>Araştırıyoruz:</b> Sektördeki yenilikleri yakından
                      takip ediyoruz ve sürekli olarak araştırma yapıyoruz. Bu
                      sayede kurtulabilen en güncel çözümleri sunabiliyoruz.
                    </p>
                    <p>
                      <b>Gelişiyoruz:</b> İnovasyon ve elektrik gücümüzle, ürün
                      ve hizmetlerimizi sürekli olarak geliştiriyoruz.
                      Ürünlerinde uygun çözümler üretmek için kapsamlı çözümler
                      geliştiriyoruz.
                    </p>
                    <p>
                      <b>Üretiyoruz:</b> Modern üretim tesislerimizde en yüksek
                      kalitede ürünler üretiyoruz.
                    </p>

                    <p class="mt-4">
                      Mekanik ve elektromekanik unsurları en optimum ve verimli
                      şekilde yöneterek mekaniye ve elektromekaniye hareket
                      kabiliyeti vererek izlenebilirliği ve uzaktan kumanda yetisi
                      sağlayacak teknolojileri tasarlayıp tedarikçisi olduğumuz
                      müşterilerimize sağlamaktayız.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>
  
  <script>
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    ScrollButton,
    Footer,
  },
};
</script>
  
  <style>
</style>