<template>
    <Navbar />
    <ScrollButton />
  
    <section class="banner-title" style="background-image: url(images/auto.png)">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="title-column col-md-6 col-sm-6 col-xs-12">
            <h1>Otomasyon Sistemleri</h1>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <ul class="bread-crumb clearfix">
              <li>
                <a href="#">Anasayfa</a>
              </li>
              <li class="active">Otomasyon Sistemleri</li>
            </ul>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Banner text end -->
    </section>
    <section class="page-section bg-light" id="portfolio">
      <div class="container">
        <div class="text-uppercase line-wrapper">
          <div class="line"></div>
          <div class="text">Çöp Şutu</div>
          <div class="line"></div>
        </div>
        <div class="row">
          <div class="text-center">
            <p>
                Bu sistem, genellikle bir tesis veya şehirdeki çöp konteynerlerinin doluluk durumunu izlemek , 
                hangi kapakların acık olduğunu gören ve takip eder Elektronik kontrollü çöp şutu kontrol sistemi, 
                sensörler ve kontrol cihazları aracılığıyla çöp konteynerlerinin doluluk seviyelerini sürekli olarak izler. 
                Doluluk seviyeleri belirli bir eşik değerine ulaştığında kapakların açılmasına izin vermez . 
                Bu şekilde, çöp toplama işlemleri daha verimli hale getirilir ve kaynaklar daha etkin bir şekilde kullanılır.
                Bu sistem ayrıca bir SCADA (Supervisory Control and Data Acquisition) sistemi içerir. 
                SCADA sistemi, tesis veya şehir genelindeki çöp şütu kontrol sistemini merkezi bir noktadan izlemek ve 
                kontrol etmek için kullanılır. SCADA, çöp konteynerlerinin doluluk seviyelerini gerçek zamanlı olarak
                 görselleştirir,ve hangi kapağın acık oldunu gösrir sistem performansını analiz eder ve gerektiğinde
                  müdahalede bulunma yeteneği sağlar. Operatörler, SCADA arayüzü üzerinden çöp toplama işlemlerini izleyebilir, 
                  verileri analiz edebilir. Bu şekilde, elektronik kontrollü çöp şütu kontrol sistemi ve SCADA entegrasyonu, 
                  çöp yönetimini daha etkin ve verimli hale getirirken, aynı zamanda merkezi bir izleme ve kontrol imkanı sunar.

            </p>
            
          </div>
        </div>
        <div class="row">
          <div class="text-uppercase line-wrapper mt-5">
            <div class="text">Galeri</div>
            <div class="line"></div>
          </div>
          <div
            class="col-lg-3 col-sm-6 mb-3"
            v-for="(item, index) in garbages"
            :key="index"
          >
            <!-- Portfolio item 1-->
            <div class="portfolio-item">
              <a
                class="portfolio-link"
                data-bs-toggle="modal"
                :href="'#portfolioModal' + index"
              >
                <div class="portfolio-hover">
                  <div class="portfolio-hover-content">
                    <font-awesome-icon icon="plus" />
                  </div>
                </div>
                <img class="img-fluid" :src="item.logo" :alt="item.baslik" />
              </a>
              <!-- <div class="portfolio-caption">
                                  <div class="portfolio-caption-heading">Threads</div>
                                  <div class="portfolio-caption-subheading text-muted">Illustration</div>
                              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <div
      class="portfolio-modal modal fade"
      v-for="(item, index) in garbages"
      :key="index"
      :id="'portfolioModal' + index"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="close-modal" data-bs-dismiss="modal">
            <img src="images/close-icon.svg" alt="Close modal" />
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="modal-body">
                  <!-- Project details-->
                  <img
                    class="img-fluid d-block mx-auto"
                    :src="item.logo"
                    alt="Otomasyon Sistemleri"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import { ref, onMounted } from "vue";
  import { firestore } from "@/firebase/config";
  import { query, collection, getDocs, where } from "firebase/firestore";
  
  import Navbar from "@/components/Navbar-2.vue";
  import ScrollButton from "@/components/ScrollButton.vue";
  import Footer from "@/components/Footer.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  
  export default {
    components: {
      Navbar,
      ScrollButton,
      Footer,
      FontAwesomeIcon,
    },
    setup() {
      const garbages = ref([]);
  
      onMounted(async () => {
        let q = query(
          collection(firestore, "copsutu"),
          where("durum", "==", true)
        );
  
        const querySnapshot = await getDocs(q);
  
        const veri = [];
  
        querySnapshot.forEach((doc) => {
          veri.push({ ...doc.data(), id: doc.id });
        });
  
        garbages.value = veri;
      });
  
      return {
        garbages,
      };
    },
  };
  </script>
    
    <style scoped>
  </style>