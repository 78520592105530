<template>
  <Navbar />
  <Slider/>
  <Content/>
  <Banner/>
  <Referances/>
  <Footer />

  </template>
  
  <script>
  import Navbar from "@/components/Navbar-2.vue";
  import Footer from "@/components/Footer.vue";
  import Slider from "@/components/Slider.vue";
  import Content from "@/components/Content.vue";
  import Referances from "@/components/Referances.vue";
  import Banner from "@/components/Banner.vue";
  
  export default {
    components: {
      Navbar,
      Footer,
      Slider,
      Content,
      Referances,
      Banner
    }
  }
  </script>
  